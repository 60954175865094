import { Link } from "react-router-dom";
import styles from "./styles/index.module.css";

function Card(props: any) {
  return (
    <Link to={`/${props.id}`} style={{textDecoration: "none"}}>
    <div className={styles.main}>
      <div>
        <span className={styles.head}>Phone number: </span> {props.phone_number}
      </div>
      <div>
        <span className={styles.head}>Invite code: </span>
        {props.invite_code}
      </div>
      <div>
        <span className={styles.head}>Referral: </span>
        {props.referral.length}
      </div>
    </div>
    </Link>
  );
}

export { Card };
