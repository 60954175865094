import { Button, Container, Nav, Navbar } from "react-bootstrap";
import styles from "./styles/index.module.css";

import reactLogo from "./images/reactLogo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../../store/context";
import { getUser } from "../../api/user";

function Header() {
  let navigate = useNavigate();
  let loc = useLocation();
  const { active, user, setIsLoading, activateUser } = useContext(UserContext);

  const handleLogout =() => {
    activateUser(false)
  }

  useEffect(() => {
    if (user && user.access) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const value = await getUser(user.access, "me");
          if (value) {
            activateUser(true);
          } else {
            activateUser(false);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (!active && loc.pathname !== "/") navigate("/");

    if (active && loc.pathname === "/") navigate("/me");
  }, [active, loc.pathname, navigate]);

  return (
    <header className={styles.container}>
      <Container fluid="xl">
        <Navbar>
          <Container className="con">
            <Navbar.Brand href="#home">
              <img
                alt=""
                src={reactLogo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{" "}
              Referral
            </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                to="/"
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/me"
              >
                Profile
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/users"
              >
                Users
              </Nav.Link>
            </Nav>

            {active && (
              <Nav>
                <span onClick={handleLogout} className={styles.logout}>Logout</span>
              </Nav>
            )}
          </Container>
        </Navbar>
      </Container>
    </header>
  );
}

export { Header };
