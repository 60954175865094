import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button, Toast } from "react-bootstrap";
import { getCodeOrToken } from "../../api/getCodeOrToken";

import styles from "./styles/index.module.css";
import { UserContext } from "../../store/context";
import { Loading } from "../Loading";

function EnterForm() {
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState();
  const [error, setError] = useState<{ phone_number: string[] } | null>(null);

  const { isLoading, active, addUser, setIsLoading } = useContext(UserContext);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (error) {
      timer = setTimeout(() => {
        setError(null);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [error]);

  const handlerSubmit = async () => {
    setIsLoading(true);
    const { res, error } = await getCodeOrToken(phone, code);
    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }
    addUser({
      phone: phone,
      access: res.access,
      refresh: res.refresh,
    });
    setCode(res.verified_code);
    setIsLoading(false);
  };

  if (isLoading) return <Loading />

  return (
    <div className={styles.main}>
      {active ? (
        <></>
      ) : (
        <Form onSubmit={handlerSubmit}>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Enter Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="+71012020303"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          {code && (
            <Form.Group className="mb-3">
              <Form.Label>Verified code</Form.Label>
              <Form.Control
                type="text"
                value={code}
                readOnly
              />
            </Form.Group>
          )}
          <Button type="submit">Submit</Button>
        </Form>
      )}
      {error && (
        <Toast style={{marginTop: "10px"}} onClose={() => setError(null)}>
          <Toast.Header>
            <div style={{ width: "90%" }}>Warning</div>
          </Toast.Header>
          <Toast.Body>{error.phone_number[0]}</Toast.Body>
        </Toast>
      )}
    </div>
  );
}

export { EnterForm };
