import { useParams } from "react-router-dom";
import { getUser, setInvateCode } from "../../api/user";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../store/context";
import { Row, Col, Container } from "react-bootstrap";

function Profile() {
  const [code, setCode] = useState("");
  const [data, setData] = useState();
  const params = useParams();
  const { user, isLoading, setIsLoading } = useContext(UserContext);

  const handleAdd = () => {
    setInvateCode(user.access, code);
    fetchData();
  };

  const fetchData = async () => {
    setIsLoading(true);
    const res = await getUser(user.access, params.id);
    setData(res);
    setIsLoading(false);
  };

  useEffect(() => {
    if (user && user.access) fetchData();
  }, []);

  if (isLoading) return <p>Loading....</p>;

  return (
    <Container className="con">
      {data ? (
        <div style={{ paddingTop: "40px", fontWeight: "700" }}>
          <Row>
            <Col>
              <h2>Profile</h2>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col>
              <span>Phone number: </span> {data.phone_number}
            </Col>
          </Row>
          <Row style={{ marginTop: "6px" }}>
            <Col>
              <span>Invite code: </span> {data.invite_code}
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Reference code: </span>{" "}
              {data.reference_code ? (
                data.reference_code
              ) : params.id === "me" ? (
                <>
                  <input
                    type="text"
                    maxLength={6}
                    size={6}
                    style={{ marginTop: "6px" }}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button
                    style={{
                      border: "2px solid #1f7a8c",
                      borderRadius: "4px",
                      background: "#1f7a8c",
                      color: "white",
                      marginLeft: "4px",
                    }}
                    onClick={handleAdd}
                  >
                    Add
                  </button>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "6px" }}>
            <Col>
              <span>Referral: </span>
            </Col>
          </Row>
          <Row
            style={{
              gap: "16px",
              padding: "0 0 0 40px",
              margin: "20px auto",
              width: "fit-content "
            }}
          >
            {data.referral.map((ref, index) => (
              <Col
                key={index}
                xs={3}
                md={2}
                style={{
                  borderRadius: "6px",
                  textAlign: "center",
                  padding: "8px",
                  background: "#1f7a8c",
                  color: "white",
                  minWidth: "fit-content",
                }}
              >
                {ref.phone_number}
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
}

export { Profile };
