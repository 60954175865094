import { BASE_URL } from "../utils/constants";

export async function getUser(access, id) {
  const response = await fetch(`${BASE_URL}/api/users/${id}/`, {
    headers: {
      "Authorization": `Bearer ${access}`
    }
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return await response.json();
}

export async function setInvateCode(access, code) {
  const response = await fetch(`${BASE_URL}/api/users/me/`, {
    method: "PATCH",
    headers: {
      "Authorization": `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "reference_code": code
    }),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return await response.json();
}
