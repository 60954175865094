export function reducer(state, { type, payload }) {
  switch (type) {
    case "ADD_USER":
      return {
        ...state,
        user: {
          phone: payload.phone,
          access: payload.access,
          refresh: payload.refresh,
          active: state.user.active,
        },
      };
    case "ACTIVATE_USER":
      return {
        ...state,
        active: payload,
      };
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: payload
      }
    default:
      return state;
  }
}
