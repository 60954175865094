import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Main } from "./components/Main";
import { Container } from "react-bootstrap";
import { EnterForm } from "./components/EnterForm";
import { UserProvider } from "./store/context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Profile } from "./components/Profile";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <BrowserRouter>
          <Header />
          <main>
            <Container fluid="xl">
              <Routes>
                <Route
                  path="/"
                  element={<EnterForm />}
                />
                <Route
                  path="/:id"
                  element={<Profile />}
                />
                <Route
                  path="/users"
                  element={<Main />}
                />
              </Routes>
            </Container>
          </main>
          <Footer />
        </BrowserRouter>
      </UserProvider>
    </QueryClientProvider>
  );
}

export default App;
