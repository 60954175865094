import { createContext, useReducer } from "react";
import { reducer } from "./reducers";

export const initialState = {
  active: false,
  isLoading: false,
  user: {
    phone: "",
    access: "",
    refresh: "",
  }
}

export const UserContext = createContext()

export const UserProvider = ({children}) => {
const [state, dispatch] = useReducer(reducer, initialState)

  const addUser = (payload) => {
    dispatch({type: "ADD_USER", payload: payload})
  }

  const activateUser = (value) => {
    dispatch({type: "ACTIVATE_USER", payload: value})
  }

  const setIsLoading = (value) => {
    dispatch({type: "SET_IS_LOADING", payload: value})
  }

  const value = {
    ...state,
    addUser,
    activateUser,
    setIsLoading,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}