import { useQuery } from "@tanstack/react-query";

import { Col, Row } from "react-bootstrap";
import { getUsers } from "../../api/getUsers";
import { Card } from "./components/Card";

function Main() {
  const { isPending, isError, data, error } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });

  if (isPending) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  if (!data || data.length < 1) return <>No found.</>;

  return (
    <Row style={{paddingTop: "50px"}}>
      {data.map((e) => (
        <Col key={e.id} xs={12} md={6} lg={3} className="p-4">
          <Card {...e} />
        </Col>
      ))}
    </Row>
  );
}

export { Main };
