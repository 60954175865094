import { BASE_URL } from "../utils/constants";

async function getCodeOrToken(phone, code) {
  const data = {
    phone_number: phone,
    verified_code: code,
  };
  const response = await fetch(`${BASE_URL}/api/auth/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  let error = undefined;
  let res = undefined;

  if (!response.ok) {
    error = await response.json();
  } else {
    res = await response.json();
  }
  return { res, error };
}

export { getCodeOrToken };
