import { Spinner } from "react-bootstrap";

function Loading() {
  return (
    <div style={{ textAlign: "center", paddingTop: "20vh" }}>
      <Spinner /> Loading....
    </div>
  );
}

export { Loading };
